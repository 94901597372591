<template>
    <div class="driver">
        <!-- 头部banner -->
        <banner :subtitle="subtitle" :logoFlag="logoFlag" :maintitle="maintitle"  :imgSrc="imgSrc"></banner>
        <!-- 主体内容 -->
        <div class="driver_cont">
            <div class="pattern cont" v-class="'pattern cont animate__animated animate__fadeInup animate__block'">
                <partTitle title="入职模式" subtitle="Pattern"></partTitle>
                <div class="second">
                    <p>四种入职模式</p>
                    <span>总有一种适合你</span>
                    <div class="second_part">
                        <div class="part" v-for="(item, index) in pattern" :key="index">
                            <img :src="item.src" alt="">
                            <span>{{item.title}}</span>
                            <p>{{item.subtitle}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="join cont" v-class="'join cont animate__animated animate__fadeInup animate__block'">
                <partTitle title="加入我们" subtitle="Join Us"></partTitle>
                <div class="second">
                    <img src="../../static/images/sjzm/jr-img.png" alt="">
                    <span>完善的福利保障，灵活的入职模式，期待您的加入！</span>
                    <p>若感兴趣请填写您的真实信息，我们将尽快与您联系</p>
                    <div class="ipt">
                        <div class="name">
                            <input type="text" maxlength="10" v-model.trim="name" placeholder="姓名"
                            @focus="inputfocus" @blur="inputblur">
                        </div>
                        <div class="mobile">
                            <input type="tel" maxlength="11" v-model.trim="mobile" placeholder="电话"
                            @focus="inputfocus" @blur="inputblur">
                        </div>
                    </div>
                    <div class="btn" @click="handleClick">确认提交</div>
                </div>
            </div> -->
        </div>
        <!-- 底部 -->
        <foot style="margin-bottom:1.08rem"></foot>
        <!-- 联系电话 -->
        <a class="contact" href="tel:400-160-8899">咨询电话：400-160-8899</a>
        <!-- toast -->
        <div v-if="beSure" class="toast animate__animated animate__fadeIn">
            <img src="../../static/images/home/confirm.png" alt="">
            提交成功
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            logoFlag: false,
            maintitle: "司机招募",
            subtitle: "多种模式 总有一种适合你",
            imgSrc: require('@/assets/images/sj-bg.png'),
            pattern: [{
                title: "专职驾驶员",
                subtitle: "国企平台、订单充足、多跑多赚、工作体面",
                src: require('../../static/images/sjzm/r-one.png')
            },{
                title: "低价合规网约车租赁",
                subtitle: "不限平台、不限流水、时间自由、用车自由",
                src: require('../../static/images/sjzm/r-two.png')
            },{
                title: "出租车驾驶员",
                subtitle: "国企平台、收入稳定、专业正规、工作自由",
                src: require('../../static/images/sjzm/r-three.png')
            },{
                title: "以租代购",
                subtitle: "不限平台、不限流水、时间自由、到期过户",
                src: require('../../static/images/sjzm/r-four.png')
            }],
            name: "",
            mobile: "",
            beSure: false,
            isClick: false,
            defaultHeight: '0',  //默认屏幕高度
            nowHeight:  '0',  //实时屏幕高度
            nameFocus:false,
            phoneFocus:false
        }
    },
    mounted() {
        this.routerTo(2);
        //获取默认高度
        this.defaultHeight = document.documentElement.clientHeight;
        window.onresize = () => {
            return (() => {
                //实时屏幕高度
                this.nowHeight =document.documentElement.clientHeight;
            })();
        };
    },
    watch:{
        nowHeight:function() {//这里注意函数名为要监听的属性
            if(this.defaultHeight!= this.nowHeight){
                //键盘弹出操作
                if(!this.nameFocus){
                    return;
                }
                // 延时函数
                setTimeout(()=>{
                    document.activeElement.scrollIntoView(false)//焦点所在元素移动到可视区域并位于底部，可选择顶部、居中
                    //底部若是被挡住可以向上滚动一定距离
                    let scrollTop=document.body.scrollTop
                    window.scrollTo(0, scrollTop+200);
                },200)
            }else{
                //键盘收起操作
            }
        }
    },
    methods: {
        //获取焦点
        inputfocus(){
            this.nameFocus = true;
        },
        //失去焦点
        inputblur(){
            this.nameFocus = false;
        },
        handleClick() {
            if(this.isClick == false) {
                var reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
                if(this.name == "") {
                    alert("姓名不能为空")
                }else if (this.mobile == "") {
                    alert("手机号不能为空")
                }else if (!reg.test(this.mobile)) {
                    alert("手机号格式不正确")
                }else {
                    this.isClick = true
                    this.$http('get',{
                        name: this.name,
                        tel: this.mobile
                    }).then(res=>{
                        this.isClick = false
                        if(res.data.returnCode == 10000){
                            this.name = '';
                            this.mobile = '';
                            this.beSure = true
                            setTimeout(() => {
                                this.beSure = false
                            }, 1000);
                        }else{
                            alert(res.data.msg);
                        }
                    })
                }
            }
        }
    }
}
</script>

<style scoped>
.animate__block{
    visibility: visible !important;
}
.animate__animated{
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-duration: var(--animate-duration);
    animation-duration: var(--animate-duration);
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    animation-delay:0.2s;
}
.animate__fadeInup{
    -webkit-animation-name: FadeInup;
    animation-name: FadeInup;
}
.animate__animated {
  --animate-duration: .5s;
}
.animate__animated.animate__fadeIn {
  --animate-duration: .5s;
}
input {
    border: none;
    background: transparent;
    outline: none;
    font-size: .24rem;
}
.driver {
    position: relative;
}
.driver_cont {
    margin-bottom: 2rem;
}
.cont {
    color: #2D2D39;
    font-size: .36rem;
    line-height: .36rem;
    margin-top: .9rem;
    visibility: hidden;
}
.cont .second {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: .48rem;
}
.join .second img{
    width: 100%;
    height: 4.5rem;
}
.join .second p, .join .second span {
    padding: 0 .8rem;
} 
.join .second span {
    margin-top: .64rem;
    font-size: .36rem;
    text-align: center;
    line-height: .5rem;
}
.join .second p {
    font-size: .24rem;
    line-height: .24rem;
    margin: .49rem 0 .87rem;
    color: #8B8B9D;
}
.join .second .ipt {
    width: calc(100% - .96rem);
}
.join .second .ipt .name, 
.join .second .ipt .mobile {
    padding: .23rem .3rem;
    border-bottom: solid .01rem #E5E5E5;
}
.join .second .ipt input {
    width: 100%;
}
/* .join .second .ipt span {
    font-size: .24rem;
    margin: 0 .4rem 0 0;
    padding: 0;
} */
.join .second .btn {
    width: calc(100% - .96rem);
    height: .88rem;
    color: #ffffff;
    font-size: .36rem;
    background: #2D2D39;
    text-align: center;
    line-height: .88rem;
    margin-top: .73rem;
}
.pattern .second {
    padding: 0 .48rem;
    font-size: .48rem;
}
.pattern .second span {
    font-weight: bold;
    margin: .25rem 0 .07rem;
}
.pattern .second .second_part {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.pattern .second .second_part .part {
    width: 3.07rem;
    background: #F4F4F4;
    display: flex;
    flex-direction: column;
    margin-top: .4rem;
}
.pattern .second .second_part .part img {
    width: 3.07rem;
    height: 1.73rem;
}
.pattern .second .second_part .part span {
    font-size: .28rem;
    margin: .23rem .24rem .13rem;
}
.pattern .second .second_part .part p {
    font-size: .24rem;
    color: #848494;
    margin: 0 .24rem .33rem;
}
.contact {
    position: fixed;
    bottom: 0;
    left: 0;
    background: #2D2D39;
    font-size: .36rem;
    color: #ffffff;
    text-align: center;
    width: 100%;
    height: 1.08rem;
    line-height: 1.08rem;
}
.toast {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 3.3rem;
    height: 1.35rem;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, .7);
    border-radius: .08rem;
    color: #ffffff;
    font-size: .24rem;
}
.toast img {
    width: .42rem;
    height: .42rem;
    margin-bottom: .125rem;
}
@keyframes FadeInup {
    0%{
        opacity: 0;
        -webkit-transform: translateY(30%);
        transform: translateY(30%);
    }
    100%{
        opacity:1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}
</style>